@font-face {
  font-family: "Salesforce Sans";
  src: url("./fonts/SalesforceSans-Regular.ttf") format("truetype");
}

.page-layout__nav-item--active {
  font-weight: bold;
  text-decoration: underline;
}
.header-row {
  margin: 2rem auto
}
