div.fullSize {
    height: 100%;
    width: 100%;
}

div.headerContainer {
    height: 10%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

p.benioffHeader {
    font-family: "Salesforce Sans";
    font-weight: bold;
    font-size: 8vmin;
    color: white;
    margin-top: 0;
    margin-left: 0;
    margin-bottom: 0;
    margin-right: -8vw;
    letter-spacing: 8vw;
}

p.blitzerHeader {
    font-family: "Salesforce Sans";
    font-weight: bold;
    font-size: 8vmin;
    color: white;
    margin-top: 0;
    margin-left: 0;
    margin-bottom: 0;
    letter-spacing: 3vw;
}

div.boardContainer {
    /*height: 98%;*/
    /*width: 98%;*/
    margin: 0.5%;
    border-radius: 1.5rem;
    background-color: rgb(32, 155, 213);
    padding-bottom: 4vmin;
    padding-left: 4vmin;
    padding-right: 4vmin;
}

div.gridContainer {
    /*height: 90%;*/
    display: grid;
    padding: 2vmin;
    background-color: white;
}

div.cellContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0.05rem #CCC solid;
    cursor: pointer;
}

div.cellContainer.topRow {
    border-top: 0;
}

div.cellContainer.bottomRow {
    border-bottom: 0;
}

div.cellContainer.firstColumn {
    border-left: 0;
}

div.cellContainer.lastColumn {
    border-right: 0;
}

div.cellContainer.bingoed {
    /* Override the margin to 0 if bingo and replace with border */
    margin: 0;
    border: 1vmin red solid;
    border-radius: 0.5rem;
}

div.selectedCell {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80%;
    width: 80%;
    border-radius: 0.5rem;
    background-color: #b1f9b8;
}

p.cellText {
    font-size: 2.8vmin;
    letter-spacing: normal;
    margin: 1vmin;
    color: black;
    font-family: "Salesforce Sans", sans-serif;
}

img.freeSpace {
    height: 15vmin;
    width: 15vmin;
}

div.overlay {
    position: absolute;
    top: 0;
    left: 0;
}

p.authorCredit {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 0.1vmin;
}
